<template>
  <div>
    <div v-if="isReady">
      <b-container>
        <h2>{{ content.title }}</h2>
      </b-container>
      <b-container
        fluid
        class="p-0 mb-4 mt-3 text-center cover-image-container"
      >
        <img
          class="cover-image-background"
          src="../assets/images/empty.svg"
          :style="{'background-image': 'url(' + content.cover_image.data.full_url + ')'}"
        >
        <img
          class="cover-image"
          :src="content.cover_image.data.full_url"
          :alt="content.cover_image.data.title"
        >
      </b-container>
      <b-container>
        <p class="text-justify">
          <vue-markdown
            quotes="«»„“"
            :source="content.text"
          />
        </p>
      </b-container>
    </div>
  </div>
</template>

<script>
  import VueMarkdown from 'vue-markdown'

  import client from '../api';

  export default {
    name: 'NewsArticle',
    components: {
      'vue-markdown': VueMarkdown
    },
    data () {
      return {
        isReady: false,
        content: {}
      }
    },
    mounted() {
      client.getItem('kasa_news', this.$route.params.newsId, {
        'fields': 'id,title,text,date,cover_image.data.full_url,cover_image.title'
      }).then(apiResponse => {
        this.content = apiResponse.data
        this.isReady = true
      })
    }
  }
</script>


<style lang="scss" scoped>
  @import '../style/bootstrap-component-include';

  .cover-image-container {
    position: relative;
    z-index: 0;

    overflow: hidden;
    .cover-image {
      position: relative;
      max-width: 100%;
      max-height: 500px;
      z-index: 2;
    }
    .cover-image-background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;

      background-attachment: fixed;
      background-size: cover;
      filter: blur(5px) opacity(0.7);
    }
  }
</style>
